<template>
  <div>
    <router-link class="come-back" to="/admin/contact_information_onk">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories"> Редактирование контактов </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createOnk" ref="createOnk">
        <div class="content-sb">
          <p>Описание<span>*</span></p>
          <input required placeholder="Введите описание" v-model="form.text" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Имя контакта (1)<span>*</span></p>
          <input required placeholder="Введите имя" v-model="form.contact_1[0]" type="text" name="link" class="input blue">
        </div>
        <div class="content-sb">
          <p>Телефон контакта (1)<span>*</span></p>
          <input required placeholder="Введите телефон" v-model="form.contact_1[1]" type="text" name="link" class="input blue">
        </div>

        <div class="content-sb">
          <p>Имя контакта (2)<span>*</span></p>
          <input required placeholder="Введите имя" v-model="form.contact_2[0]" type="text" name="link" class="input blue">
        </div>
        <div class="content-sb">
          <p>Телефон контакта (2)<span>*</span></p>
          <input required placeholder="Введите телефон" v-model="form.contact_2[1]" type="text" name="link" class="input blue">
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/contact_information_onk" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'EditContactInformationOnk',
  data () {
    return {
      form: {
        text: '',
        id: '',
        contact_1: [],
        contact_2: []
      }
    }
  },
  methods: {
    createOnk () {
      const formData = new FormData()
      formData.append('text', this.form.text)
      formData.append('id', this.form.id)
      formData.append('contact_1', JSON.stringify(this.form.contact_1))
      formData.append('contact_2', JSON.stringify(this.form.contact_2))
      this.$store.dispatch('editContactInformationOnk', { id: this.form.id, data: formData })
    }
  },
  mounted () {
    // // if (this.$store.getters.getContactInformationOnk.length === 0) {
    this.$store.dispatch('setContactInformationOnk')
  },
  watch: {
    '$store.getters.getContactInformationOnk' () {
      this.form = this.$store.getters.getContactInformationOnk
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
